:root {
    --bg-white: white;
    --bg-shadow: #cbd5e1;
    --text-color-primary: #1e293b;
    --text-color-secondary: #64748b;
    --border-color: #e2e8f0;
    --pagination: #4F79DB;
    --pagination-hover: #e2e8f0;
    --pagination-quick: #e2e8f0;
}

.dataTable_Box {
    /* position: relative; */
    display: flex;
    width: 100%;
    flex-direction: column;
    box-shadow: 0px 5px 10px var(--bg-shadow);
    background-color: var(--bg-white);
    border-radius: 1rem;
}

.dataTable_Header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
}

.dataTable_Search {
    height: 2.5rem;
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.625rem;
    border-radius: 0.5rem;
    border: 1px solid #e2e8f0;
    background-color: var(--bg-white);
    padding: 0.625rem;
}

.dataTable_Search div {
    display: flex;
    align-items: center;
}

.dataTable_Search div>svg {
    width: 20px;
    height: 20px;
    color: #64748b;
}

.dataTable_Search input {
    width: 100%;
    outline: none;
    border: none;
}

.dataTable_Select {
    position: relative;
}

.dataTable_Select select {
    padding-left: 10px;
    padding-right: 28px;
    height: 40px;
    color: var(--text-color-secondary);
    border: 1px solid var(--border-color);
    background-color: var(--bg-white);
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.5rem;
}

.dataTable_Select::after {
    position: absolute;
    top: 8px;
    right: 4px;
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAANFJREFUSEtjZKAxYKSx+QyjFhAM4dEgGuRBlFLS3fDnD8uEBRMKP2BzakJBvwALy5/8OT2ljbi8gjOSQYYzMDDUMzAwXPjzh8UR3RKo4fsZGBgMGBgYGuf0lILUYwCcFoAMYGX5c+A/A4M+uiXIhjMyMFz8/YfFAZcv8SZTbJaAnMjC8gfsckKGg9QSzAfolkDDgCjDibIApAjNEpCr8AYLckQQ9AFMMcwSEB9fmKPHMtEWwHwConFFKEmpiGAWJVIBST4g0kwUZaMWEAy1oR9EAHVHXRkLp6JEAAAAAElFTkSuQmCC");
    font-size: 16px;
}



.dataTable_Body {
    position: relative;
}

.dataTable {
    width: 100%;
    border-collapse: collapse;
}
.dataTable thead tr th {
    padding: 20px;
    background-color: var(--bg-white);
    color: var(--text-color-secondary);
    text-align: left;
    font-weight: 500;
}

.dataTable thead tr th div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.dataTable thead tr th .sort {
    display: flex;
    flex-direction: row;
    gap: 1px;
    color: #d3d4d6;
    font-size: 14px;
}

.dataTable thead tr th .sort::before {
    /* up */
    display: inline-flex;
    content: '';
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PgogICAgICAgIDwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgCiAgICAgICAgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+ICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBmaWxsPSJjdXJyZW50Q29sb3IiIGNsYXNzPSJiaSBiaS1hcnJvdy11cCIgdmlld0JveD0iMCAwIDE2IDE2Ij4gPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNOCAxNWEuNS41IDAgMCAwIC41LS41VjIuNzA3bDMuMTQ2IDMuMTQ3YS41LjUgMCAwIDAgLjcwOC0uNzA4bC00LTRhLjUuNSAwIDAgMC0uNzA4IDBsLTQgNGEuNS41IDAgMSAwIC43MDguNzA4TDcuNSAyLjcwN1YxNC41YS41LjUgMCAwIDAgLjUuNXoiLz4gPC9zdmc+ICA=");
    background-size: 14px 14px;
    height: 14px;
    width: 11px;
    opacity: 0.2;
}

.dataTable thead tr th .sort::after {
    /* down */
    display: inline-flex;
    content: '';
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PgogICAgICAgIDwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgCiAgICAgICAgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+ICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBmaWxsPSJjdXJyZW50Q29sb3IiIGNsYXNzPSJiaSBiaS1hcnJvdy1kb3duIiB2aWV3Qm94PSIwIDAgMTYgMTYiPiA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDFhLjUuNSAwIDAgMSAuNS41djExLjc5M2wzLjE0Ni0zLjE0N2EuNS41IDAgMCAxIC43MDguNzA4bC00IDRhLjUuNSAwIDAgMS0uNzA4IDBsLTQtNGEuNS41IDAgMCAxIC43MDgtLjcwOEw3LjUgMTMuMjkzVjEuNUEuNS41IDAgMCAxIDggMXoiLz4gPC9zdmc+ICA=");
    background-size: 14px 14px;
    height: 14px;
    width: 11px;
    opacity: 0.2;
}

.dataTable thead tr th .sort.asc::before,
.dataTable thead tr th .sort.desc::after {
    color: #3F434A;
    opacity: 1;
}

.dataTable thead tr {
    border-top: 1px solid var(--border-color);
}

.dataTable thead tr,
.dataTable tbody tr {
    border-bottom: 1px solid var(--border-color);
    transition: all 0.3s ease-in-out;
}

.dataTable tbody tr:hover {
    background-color: #f1f5f9;
}

.dataTable tbody tr td {
    padding: 14px 20px;
    vertical-align: top;
    font-size: 0.8rem;
}

.dataTable_Footer {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.showingInfo {
    flex: 1 1 0%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: var(--text-color-secondary);
}

.pagination {
    flex: 1 1 0%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
}

.pagination button {
    width: 32px;
    height: 32px;
    border-radius: 0.5rem;
    text-align: center;
    color: var(--text-color-primary);
    border: none;
}

.btnPage {
    background-color: var(--bg-white);
    color: var(--text-color-primary);
    transition: all 0.3s ease-in-out;
}

.btnPage:hover {
    background-color: var(--pagination-hover);
    color: var(--text-color-primary) !important;
}

.btnPage_Active {
    background-color: var(--pagination);
    color: var(--bg-white) !important;
}

.btnQuick {
    background-color: var(--pagination-quick);
    color: var(--text-color-primary);
}