@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-body text-base text-text-primary;
    color: #1e293b;
  }

  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }

  small {
    @apply text-sm;
  }

  p {
    @apply mb-2;
  }

  a {
    color: #3b82f6;
  }

  ul {
    list-style-type: disc;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
  }
  ol {
    list-style-type: decimal;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
  }
}

@layer utilities {
  /* SLIM SCROLLBAR */
  /* Works on Firefox */
  .sidebar-overflow {
    scrollbar-width: thin;
    scrollbar-color: #a8a29e #1e293b;
  }

  /* Works on Chrome, Edge, and Safari */
  .sidebar-overflow::-webkit-scrollbar {
    width: 12px;
  }

  .sidebar-overflow::-webkit-scrollbar-track {
    @apply bg-sidebar;
  }

  .sidebar-overflow::-webkit-scrollbar-thumb {
    @apply bg-slate-tertiary rounded-full border border-solid border-4 border-sidebar;
  }

  /* SLIM SCROLLBAR */
  /* Works on Firefox */
  .slim-overflow {
    scrollbar-width: thin;
    scrollbar-color: #a8a29e #fafaf9;
  }

  /* Works on Chrome, Edge, and Safari */
  .slim-overflow::-webkit-scrollbar {
    width: 12px;
  }

  .slim-overflow::-webkit-scrollbar-track {
    @apply bg-white;
  }

  .slim-overflow::-webkit-scrollbar-thumb {
    @apply bg-slate-tertiary rounded-full border border-solid border-4 border-white;
  }

  .slim-x-overflow {
    scrollbar-width: thin;
    scrollbar-color: #a8a29e #fafaf9;
  }

  /* Works on Chrome, Edge, and Safari */
  .slim-x-overflow::-webkit-scrollbar {
    height: 14px;
  }

  .slim-x-overflow::-webkit-scrollbar-track {
    @apply bg-white;
  }

  .slim-x-overflow::-webkit-scrollbar-thumb {
    @apply bg-slate-tertiary rounded-full border border-solid border-4 border-white;
  }

  /* Transparent SCROLLBAR */
  /* Works on Firefox */
  .transparent-overflow {
    scrollbar-width: thin;
    scrollbar-color: #a8a29e transparent;
  }

  /* Works on Chrome, Edge, and Safari */
  .transparent-overflow::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .transparent-overflow::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  .transparent-overflow::-webkit-scrollbar-thumb {
    @apply bg-slate-tertiary rounded-full;
  }

  /* invisible SCROLLBAR */
  /* Works on Firefox */
  .invisible-overflow {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

  /* Works on Chrome, Edge, and Safari */
  .invisible-overflow::-webkit-scrollbar {
    width: 0;
  }

  .invisible-overflow::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  .invisible-overflow::-webkit-scrollbar-thumb {
    @apply bg-transparent rounded-full border border-solid border-4 border-transparent;
  }
}

/* Sidebar */
.sidebar-container {
  @apply bg-sidebar w-0 h-0 md:w-64 md:h-full md:fixed md:top-0 bottom-0 shadow-lg shadow-slate-secondary;
}

.sidebar {
  @apply bg-sidebar py-3 p-6;
}

.sidebar .header {
  @apply py-2 px-4 text-sm text-slate-tertiary text-opacity-50;
}

.sidebar .header > span {
  @apply w-full block;
}

.sidebar .item {
  @apply text-white;
}

.sidebar .item > a {
  @apply py-2 px-4 h-12 w-full block flex items-center gap-3 cursor-pointer transition duration-300 text-white hover:bg-sidebar-hover hover:bg-opacity-30 rounded-md;
}

.sidebar .item > a.active {
  @apply bg-sidebar-active text-white font-semibold;
}

/* Background Color */
/* .bg-success {
  @apply text-white bg-success border border-success hover:bg-success-hover hover:border-success-hover;
}

.bg-warning {
  @apply text-white bg-warning border border-warning hover:bg-warning-hover hover:border-warning-hover;
}

.bg-danger {
  @apply text-white bg-danger border border-danger hover:bg-danger-hover hover:border-danger-hover;
}

.bg-info {
  @apply text-white bg-info border border-info hover:bg-info-hover hover:border-info-hover;
}

.bg-disabled {
  @apply text-text-primary bg-slate-tertiary border border-slate-secondary cursor-not-allowed;
} */

/* Page */
.page {
  @apply ml-0 md:ml-64 z-0;
}

.top-nav {
  @apply h-12 md:h-fit flex flex-row justify-between items-center bg-white fixed top-0 left-0 md:left-64 right-0 z-10;
}

.top-nav .left {
  @apply px-4 flex-1 md:hidden;
}

.top-nav .left img {
  @apply h-6;
}

.top-nav .right {
  @apply px-4 md:py-3 md:px-6 flex-1 flex flex-row items-center justify-end gap-2;
}

.page-header {
  @apply mb-4 w-full h-auto flex flex-row items-center justify-between;
}

.page-header-title {
  @apply text-4xl text-text-primary font-semibold;
}

.page-header-title small {
  @apply text-sm text-text-secondary font-normal;
}

.page-header-action {
  @apply flex flex-row items-center content-end gap-2;
}

.page-title {
  @apply text-text-primary font-semibold;
}

.page-subtitle {
  @apply text-slate-secondary;
}

.page-content {
  @apply mt-16 pb-4 px-4 md:mt-16 md:pb-6 md:p-6 overflow-x-hidden;
}

/* Page Action */
.page-action {
  @apply mb-6 w-full flex flex-row items-center justify-end gap-2;
}

/* Card */
.card {
  @apply py-4 space-y-4 w-full bg-white shadow-lg shadow-shadow rounded-2xl relative;
}
.card-header {
  @apply px-4 w-full flex flex-row items-start justify-between gap-4;
}
.card-title {
  @apply flex-1;
}
.card-filter {
  @apply flex-1 flex flex-row items-center justify-start gap-2;
}
.card-title > span {
  @apply block text-lg text-text-primary font-medium;
}
.card-action {
  @apply flex-none flex items-center justify-end gap-2;
}
.card-body {
  @apply px-4 w-full;
}
.card-footer {
  @apply px-4 w-full;
}

/* Small Card */
.small-card {
  @apply p-4 w-full flex flex-col gap-2 bg-white shadow-lg shadow-shadow rounded-2xl relative;
}
.small-card-title {
  @apply w-full block text-text-primary font-semibold;
}
.small-card-content {
  @apply w-full h-full flex flex-row items-center gap-4;
}

/* TYPO */
.text-primary {
  @apply text-text-primary;
}

.text-secondary {
  @apply text-slate-secondary;
}

.text-tertiary {
  @apply text-slate-tertiary;
}

/* BUTTON */
.btn {
  @apply pl-4 pr-4 h-10 flex items-center gap-[0.5rem] transition duration-300 hover:ease-in-out rounded-md;
}

.btn-sm {
  @apply h-8 text-sm rounded-md;
}

.btn-xs {
  @apply h-6 text-xs rounded;
}

.btn > svg {
  margin-left: -0.15rem;
  height: 18px;
  width: 18px;
}

.btn-sm > svg {
  height: 14px;
  width: 14px;
}

.btn-xs > svg {
  height: 12px;
  width: 12px;
}

.btn-primary {
  @apply text-white bg-primary border border-primary hover:bg-primary-hover hover:border-primary-hover;
}

.btn-secondary {
  @apply text-text-primary bg-white border border-slate-secondary hover:bg-slate-tertiary;
}

.btn-tertiary {
  @apply text-primary hover:text-primary-hover bg-transparent border border-transparent;
}

.btn-success {
  @apply text-white bg-success border border-success hover:bg-success-hover hover:border-success-hover;
}

.btn-warning {
  @apply text-white bg-warning border border-warning hover:bg-warning-hover hover:border-warning-hover;
}

.btn-danger {
  @apply text-white bg-danger border border-danger hover:bg-danger-hover hover:border-danger-hover;
}

.btn-info {
  @apply text-white bg-info border border-info hover:bg-info-hover hover:border-info-hover;
}

.btn-disabled {
  @apply text-text-primary border bg-slate-tertiary border-slate-secondary cursor-not-allowed;
}

/* ICON BUTTON SQUARE */
.btn-icon {
  @apply w-10 h-10 text-sm flex items-center justify-center transition duration-300 hover:ease-in-out rounded-md;
}

.btn-icon-sm {
  @apply w-8 h-8 rounded-md;
}
.btn-icon-xs {
  @apply w-6 h-6 rounded;
}

.btn-icon > svg {
  height: 18px;
  width: 18px;
}
.btn-icon-sm > svg {
  height: 16px;
  width: 16px;
}
.btn-icon-xs > svg {
  height: 14px;
  width: 14px;
}

/* ICON BUTTON ROUNDED */
.btn-rounded {
  @apply w-10 h-10 rounded-full text-sm flex items-center justify-center transition duration-300 hover:ease-in-out;
}

.btn-rounded-sm {
  @apply w-8 h-8;
}

.btn-rounded-xs {
  @apply w-6 h-6;
}

.btn-rounded > svg {
  height: 18px;
  width: 18px;
}

.btn-rounded-sm > svg {
  height: 16px;
  width: 16px;
}

.btn-rounded-xs > svg {
  height: 14px;
  width: 14px;
}

/* BUTTON LINK */
.btn-link {
  @apply flex items-center gap-[0.5rem] transition duration-300 hover:ease-in-out cursor-pointer;
  color: #3b82f6;
}

.btn-link-sm {
  @apply text-sm;
}

.btn-link-xs {
  @apply text-xs;
}

.btn-link > svg {
  margin-left: -0.15rem;
  height: 18px;
  width: 18px;
}

.btn-link-sm > svg {
  height: 14px;
  width: 14px;
}

.btn-link-xs > svg {
  height: 12px;
  width: 12px;
}

/* BADGE */
.badge {
  @apply py-1 px-4 w-max rounded-full text-xs font-normal flex items-center gap-[0.5rem] inline-flex;
}

.badge-primary {
  @apply text-white bg-primary border border-primary;
}

.badge-white {
  @apply text-text-primary bg-white border border-white;
}

.badge-success {
  @apply text-white bg-success border border-success;
}

.badge-warning {
  @apply text-white bg-warning border border-warning;
}

.badge-danger {
  @apply text-white bg-danger border border-danger;
}

.badge-info {
  @apply text-white bg-info border border-info;
}

.badge-Open {
  @apply text-white bg-open border border-open;
}

.badge-InProgress,
.badge-Pending {
  @apply text-white bg-inprogress border border-inprogress;
}

.badge-Finish {
  @apply text-white bg-finish border border-finish;
}
.badge-Completed,
.badge-Approved {
  @apply text-white bg-completed border border-completed;
}

.badge-Cancel,
.badge-Canceled,
.badge-Rejected {
  @apply text-white bg-canceled border border-canceled;
}

/* TABLE */
.table {
  @apply w-full border-collapse border border-slate-tertiary table-auto;
}

.table thead tr th {
  @apply px-5 py-3 bg-white text-text-primary text-left font-medium;
}

.table thead tr,
.table tbody tr {
  @apply border-b border-slate-tertiary;
}

.table tbody tr:hover {
  @apply bg-slate-tertiary bg-opacity-30;
}

.table tbody tr:last-child {
  @apply border-b-0;
}

.table tbody tr th {
  @apply px-5 py-3 bg-slate-tertiary text-text-primary text-left font-semibold;
}

.table tbody tr td {
  @apply px-5 py-3;
}

/* DATATABLE CUSTOM */
.btn-action {
  color: #94a3b8;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.btn-action:hover {
  color: #4f79db;
}

/* OVERLAY */
.overlay {
  @apply py-8 fixed top-0 right-0 left-0 overflow-y-auto overflow-x-hidden h-full w-full bg-overlay bg-opacity-30;
}

/* POPUP */
.popup {
  @apply relative h-full md:h-full flex items-center justify-center;
}

.popup-card {
  @apply mb-8 w-10/12 sm:w-6/12 md:w-96 max-h-screen p-6 bg-white shadow-lg flex flex-col gap-2 items-center text-center transition duration-300 ease-in-out rounded-xl;
}
.popup-title {
  @apply mb-2 text-2xl md:text-3xl text-text-primary font-bold;
}
.popup-message {
  @apply text-sm md:text-base text-text-secondary;
}
.popup-button {
  @apply w-full mt-6 flex flex-row items-center justify-center gap-2;
}

/* FORM */
.form-group {
  @apply mb-4 block w-full;
}

.control-label {
  @apply mb-1.5 block text-text-primary font-semibold;
}

input.form-control {
  @apply p-2.5 h-10 w-full bg-white border border-slate-tertiary focus:outline-none focus:border-slate-secondary rounded-md;
}

textarea.form-control {
  @apply p-2.5 h-10 w-full bg-white border border-slate-tertiary focus:outline-none focus:border-slate-secondary rounded-md;
}

select.form-control {
  @apply px-2.5 h-10 w-full bg-white border border-slate-tertiary focus:outline-none focus:border-slate-secondary rounded-md;
}

.form-control.disabled {
  @apply bg-slate-tertiary text-slate-secondary;
}

.form-checkbox {
  @apply inline-flex items-center;
}

.form-checkbox > input[type="checkbox"] {
  @apply h-4 w-4;
}

.form-checkbox > span {
  @apply ml-2;
}

.form-radio {
  @apply py-2 h-10 inline-flex items-center;
}

.form-radio > input[type="radio"] {
  @apply h-5 w-5;
}

.form-radio > span {
  @apply ml-2;
}

textarea.form-control {
  @apply h-24;
}

.form-control-search {
  @apply p-2.5 pl-10 h-10 w-full bg-white border border-slate-tertiary outline-none rounded-md;
}

.search-group {
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.625rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
  background-color: var(--bg-white);
  padding: 0.625rem;
}

.search-group div {
  display: flex;
  align-items: center;
}

.search-group div > svg {
  width: 20px;
  height: 20px;
  color: #64748b;
}

.search-group input {
  width: 100%;
  outline: none;
  border: none;
}

/* DATATABLE TIMESHEET */
.dataTable-timesheet {
  @apply w-full border-collapse border border-slate-tertiary;
}

.dataTable-timesheet thead tr th {
  @apply px-3 py-4 bg-slate-tertiary text-sm text-text-primary text-left font-semibold;
}

.dataTable-timesheet tbody tr {
  @apply border-b border-slate-tertiary;
}

.dataTable-timesheet tbody tr:last-child {
  @apply border-b-0;
}

.dataTable-timesheet tbody tr td {
  @apply p-3.5 text-sm;
}

/* MODAL */
.modal {
  @apply relative h-fit lg:h-full flex items-center justify-center;
}

.modal-small {
  @apply relative m-auto w-11/12 md:w-3/12;
}

.modal-medium {
  @apply relative m-auto w-11/12 md:w-6/12;
}

.modal-large {
  @apply relative m-auto w-11/12 md:w-9/12;
}

.modal-card {
  @apply mb-8 p-4 relative w-full bg-white shadow-lg h-fit overflow-y-auto rounded-xl;
}

.modal-header {
  @apply mb-6 flex flex-row;
}

.modal-content {
  @apply w-full;
}

.modal-title {
  @apply font-semibold text-lg flex-1;
}

.modal-close {
  @apply p-2 rounded-full text-slate-secondary transition duration-500 bg-white hover:bg-slate-tertiary hover:text-text-primary;
}

/* DETAIL */
.label-detail {
  @apply mb-1.5 block w-full font-semibold;
}

.card-detail {
  @apply py-2 px-4 border border-slate-tertiary bg-slate-tertiary rounded-lg;
}

p {
  @apply mb-1.5;
}

/* CARD TASK KANBAN */
.ctk {
  @apply p-4 w-full flex flex-col bg-white shadow-lg shadow-shadow rounded-2xl relative;
}

.ctk-header {
  @apply pb-2;
}

.ctk-title {
  @apply block text-text-primary font-semibold;
}

.ctk-title a {
  @apply hover:text-primary-hover transition duration-300 ease-in-out;
}

.ctk-project {
  @apply py-2 block text-text-secondary flex flex-row gap-2 items-start;
}

.ctk-project svg {
  @apply mt-1;
}

.ctk-footer {
  @apply pt-2 flex flex-row gap-2 text-text-secondary text-sm;
}

.ctk-footer .ctk-date {
  @apply flex-1 flex flex-row gap-2 items-center justify-start;
}

.ctk-footer .ctk-date svg {
  height: 16px;
  width: 16px;
}

.ctk-footer .ctk-image {
  @apply self-end;
}

.ctk-footer .ctk-image > img {
  @apply avatar-xs;
}

/* Notification Card */
.notification-container {
  @apply w-full h-screen fixed top-0 left-0 z-50;
}
.notification-card {
  @apply w-full md:w-80 bg-white shadow-lg shadow-shadow rounded-lg absolute top-12 md:top-12 md:right-20;
}
.notification-card-title {
  @apply p-4 w-full block font-semibold;
}
.notification-content {
  @apply flex flex-col mb-2 px-4 overflow-y-auto slim-overflow max-h-[250px] divide-y divide-slate-tertiary;
}
.notification-list {
  @apply py-4;
}
.notification-list .header {
  @apply mb-2 flex flex-row gap-2 items-center text-sm;
}
.notification-list .title {
  @apply flex-1 text-text-primary font-semibold truncate;
}
.notification-list .date {
  @apply flex-none text-text-secondary text-xs;
}
.notification-list .content p {
  @apply text-sm text-text-secondary line-clamp-2;
}

/* Account Card */
.account-container {
  @apply w-full h-screen fixed top-0 left-0 z-50;
}

.account-card {
  @apply w-full md:max-w-max bg-white shadow-lg shadow-shadow rounded-lg absolute top-12 md:top-16 md:right-2;
}

.account-card .body {
  @apply p-4 flex flex-col items-center justify-start gap-4;
}

/* Activity Card */
.activity-card {
  @apply bg-white shadow-lg shadow-shadow rounded-2xl h-full;
}

.activity-card-title {
  @apply p-4 w-full block text-lg font-semibold;
}

.activity-content {
  @apply flex flex-col mb-2 px-4 overflow-y-auto slim-overflow;
}

.activity-content a {
  @apply text-primary-hover;
}

.activity-list .header {
  @apply flex flex-row gap-2 items-center;
}

.activity-list .header > img {
  @apply avatar-sm;
}

.activity-list .name {
  @apply flex-1 text-text-primary font-semibold truncate;
}

.activity-list .title {
  @apply flex-1 text-text-primary font-semibold truncate;
}

.activity-list .date {
  @apply flex-none text-text-secondary text-sm;
}

.activity-list .content {
  @apply ml-[15px] pl-[23px] pb-2 border-l-2 border-slate-tertiary;
}

.activity-list .content p {
  @apply p-2 bg-slate-tertiary bg-opacity-50 rounded text-sm text-text-secondary;
}

.activity-list:last-child .content {
  @apply border-white;
}
/* .activity-card {
  @apply bg-white shadow-lg shadow-shadow rounded-xl h-full
}

.activity-card-title {
  @apply p-6 w-full block font-semibold
}

.activity-content {
  @apply flex flex-col mb-2 px-6 overflow-y-auto slim-overflow;
  max-height: 475px;
}

.activity-content a {
  @apply text-primary-hover
}

.activity-list .header {
  @apply flex flex-row gap-2 items-center
}

.activity-list .header > img {
  @apply avatar-sm
}
.activity-list .name {
  @apply flex-1 text-text-primary font-semibold truncate
}

.activity-list .title {
  @apply flex-1 text-text-primary font-semibold truncate
}

.activity-list .date {
  @apply flex-none text-text-secondary text-sm
}

.activity-list .content {
  @apply ml-[15px] pl-[23px] pb-2 border-l-2 border-slate-tertiary
}

.activity-list .content p {
  @apply p-2 bg-slate-tertiary bg-opacity-50 rounded text-sm text-text-secondary
}

.activity-list:last-child .content {
  @apply border-white
} */

/* Avatar */
.avatar-xs {
  @apply h-7 w-7 rounded-full;
}

.avatar-sm {
  @apply h-8 w-8 rounded-full;
}

.avatar-md {
  @apply h-12 w-12 rounded-full;
}

/* Tabs */
.tabs {
  @apply w-full;
}

.tab-nav {
  @apply mb-2 p-0 w-full relative flex flex-row flex-wrap gap-2;
}

.tab-nav button {
  @apply pl-4 pr-4 h-9 text-base flex items-center gap-[0.5rem] transition duration-300 hover:ease-in-out rounded-md text-text-primary bg-white border border-white hover:bg-slate-tertiary hover:border-slate-tertiary shadow-lg shadow-shadow;
}

.tab-nav button > svg {
  margin-left: -0.15rem;
  height: 15px;
  width: 15px;
}

.tab-nav button.active {
  @apply btn-primary;
}

.tab-panel {
  @apply w-full;
}

/* Deadline Box */
.deadline-box {
  @apply w-full h-full flex flex-col gap-2;
}

.deadline-box .box-header {
  @apply py-2 px-4 w-full shadow-shadow shadow-lg rounded-lg relative flex flex-row items-center gap-2;
  z-index: 1;
}

.deadline-box .box-header .title {
  @apply flex-1 font-semibold text-white text-base;
}

.deadline-box .box-header .info {
  @apply flex-none self-end;
}

.deadline-box .box-content {
  @apply py-4 bg-white shadow-shadow shadow-lg rounded-xl;
  z-index: 2;
  max-height: 644px;
}

.deadline-box .box-content .list {
  @apply px-4 flex flex-col gap-4 overflow-y-auto slim-overflow;
}

.deadline-box .box-content .list .ctk {
  @apply shadow-none border border-slate-tertiary;
}

/* Kanban */
.kanban-box {
  @apply w-full h-full space-y-2;
}

.kanban-box .box-header {
  @apply py-2 px-4 w-full shadow-lg shadow-shadow rounded-lg relative flex flex-row items-center gap-2;
}

.kanban-box .box-header .title {
  @apply flex-1 font-semibold text-white text-base;
}

.kanban-box .box-header .info {
  @apply flex-none self-end;
}

.kanban-box .box-content {
  @apply w-full min-h-full space-y-4;
}

/* Loading Animation */
.loading {
  @apply bg-slate-tertiary rounded animate-pulse;
}

/* Employee Info */
.team-info-sm,
.team-info-md {
  @apply flex flex-row items-center gap-4;
}

.team-info-sm .info,
.team-info-md .info {
  @apply truncate;
}

.team-info-sm img {
  @apply avatar-sm;
}

.team-info-sm .team-name {
  @apply block;
}

.team-info-sm .position-name {
  @apply block text-sm text-text-secondary;
}

.team-info-md img {
  @apply avatar-md;
}

.team-info-md .team-name {
  @apply block font-semibold;
}

.team-info-md .position-name {
  @apply block text-sm text-text-secondary;
}

/* Modal upload */
.modal-upload-box {
  @apply p-6 w-full h-60 flex flex-col border-2 border-dashed border-slate-secondary rounded-md;
}
.modal-upload-box .body {
  @apply w-full flex-1 flex flex-col items-center justify-center;
}


/* quill css */
.ql-toolbar.ql-snow {
  border: 1px solid #e2e8f0 !important;
  border-bottom: none!important;
  border-radius: 0.375rem 0.375rem 0 0 !important;
}

.ql-container.ql-snow {
  border: 1px solid #e2e8f0 !important;  
  border-radius: 0 0 0.375rem 0.375rem !important;
}